import { DataStore, SortDirection , Predicates, API } from 'aws-amplify';
import { TextField, Button, Table, TableBody, TableRow, TableCell, SelectField } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';

import { PublicComponent, User, CryptoAddress, CryptoTransaction, MountainwolfTransaction, Lot, Commission, Withdrawal, ReturnOnInvestment } from './models';

import { Counter } from './ApplicationContext';

import GPImage from './GPImage';

const GPConstants = {
  tronUSDTContractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  startDateISOString: "2021-12-01T00:00:00.000Z",
  startNumber: 1,
  nextMultiplicator: 6,
  lotStartNumber: 1,
  lotCount: 50000,
  lotUSDTValue: 100.0,
  agio: 0.05,
  commissionLevelPercentages: [ 0.1, 0.04, 0.03, 0.02, 0.01 ]
};

async function withdrawUSDTAmountToAddress(withdrawalAmount, selectedCryptoAddress, setInputTextFieldValue, setButtonLoading, setErrorMessage) {
  try {
    setButtonLoading(true);

    let result;
    try {
      result = await API.post("growthpoolREST", "/userData",  { body: { userDataOperation: "withdrawAmount", userDataParameters: { amount: withdrawalAmount, walletAddress: selectedCryptoAddress } } } ); 
    } catch (e) {
      console.error("Exception:", e);
    }
    
    setInputTextFieldValue("");
    setButtonLoading(false);
    
    if(!result.success && result.message) {
      setErrorMessage(result.message);
    }
    else {
      setErrorMessage(null);
    }
  }
  catch(e) {
    setErrorMessage("Amout could not be scheduled for withdrawal, due to an error.");
  }
}

async function addWalletAddress(address, setInputTextFieldValue, setButtonLoading, setErrorMessage) {
  try {
    setButtonLoading(true);
    //console.log("add userWalletAddress address:", address);
    let result;
    try {
      result = await API.post("growthpoolREST", "/userWalletAddress", { body: { walletOperation: "add", walletAddress: address } });
    } catch (e) {
      console.error("Exception:", e);
    }
    
    //console.log("userWalletAddress result:", result);
    setInputTextFieldValue("");
    setButtonLoading(false);
    
    if(!result.success && result.message) {
      setErrorMessage(result.message);
    }
  }
  catch(e) {
    setErrorMessage("Address could not be added, due to an error.");
  }
}

async function removeWalletAddress(address, setButtonLoading, setErrorMessage) {
  try {
    setButtonLoading(true);
    //console.log("remove userWalletAddress address:", address);
    let result;
    try {
      result = await API.post("growthpoolREST", "/userWalletAddress", { body: { walletOperation: "remove", walletAddress: address } });
    } catch (e) {
      console.error("Exception:", e);
    }
    
    //console.log("userWalletAddress result:", result);
    setButtonLoading(false);
    
    if(!result.success && result.message) {
      setErrorMessage(result.message);
    }
  }
  catch(e) {
    setErrorMessage("Address could not be added, due to an error.");
  }
}

function calculateAllTronUSDTBalances(cryptoTransactions, mountainwolfTransactions, lots, commissions, withdrawals, performance, rois) {
  const balances = {incoming: 0.0, outgoing: 0.0, lot: 0.0, gain: 0.0, performance: 0.0, commission: 0.0, c1: 0.0, c2: 0.0, available: 0.0, withdrawable: 0.0, withdraw: 0.0, withdrawed: 0.0};
  let irregularLotValue = 0;

  for(const cryptoTransaction of cryptoTransactions) {
    if(cryptoTransaction.contractAddress === GPConstants.tronUSDTContractAddress && cryptoTransaction.confirmed && cryptoTransaction.contractRet === "SUCCESS") {
      if(cryptoTransaction.direction === "INCOMING") {
        balances.incoming += cryptoTransaction.amount;
      }
      else if(cryptoTransaction.direction === "OUTGOING") {
        balances.outgoing += cryptoTransaction.amount;
      }
    }
  }
  
  for(const withdrawal of withdrawals) {
    if(withdrawal.contractAddress === GPConstants.tronUSDTContractAddress && withdrawal.date && withdrawal.hash) {
      balances.withdrawed += withdrawal.amount;
    }
    else if(withdrawal.contractAddress === GPConstants.tronUSDTContractAddress) {
      balances.withdraw += withdrawal.amount;
    }
  }

  for(const lot of lots) {
    balances.lot += lot.value;
    if(lot.irregular) {
      irregularLotValue += lot.value;
    }

    if(isClosedLotOrCommission(lot, performance, rois)) {
      balances.gain += 2 * lot.value;
    }
    else {
      balances.performance += performanceValueForLot(lot, performance, rois);
    }
  }
  
  for(const commission of commissions) {
    if(commission.level) { 
      balances.commission += commission.value; // C1
      balances.c1 += commission.value; // C1
      
      if(isClosedLotOrCommission(commission, performance, rois)) {
        balances.commission += commission.value; // C2
        balances.c2 += commission.value; // C2
      }
    }
  }
  
  balances.available = balances.incoming - balances.outgoing - ((balances.lot - irregularLotValue) * (1 + GPConstants.agio));

  balances.withdrawable = balances.gain + balances.performance + balances.commission - balances.withdrawed - balances.withdraw;
  balances.withdrawable = balances.withdrawable < 0 ? 0.0 : balances.withdrawable;

  return balances;
}

function performanceValueForLot(lot, performance, rois) {
  const lotCount = lotCountForPoolNumberAndLotNumber(lot.poolNumber, lot.endNumber);
  const lotCountClosing = lotCountForPoolNumberAndLotNumber(lot.poolNumber + 1, lot.endNumber * GPConstants.nextMultiplicator);
  const lotCountROI = lotCountROIFromDate(lot.date, rois);
  
  if(performance.equivalentLotCount + lotCountROI <= lotCount) {
    return 0;
  }
  else if(performance.equivalentLotCount + lotCountROI >= lotCountClosing) {
    return lot.value;
  }
  
  let performanceValue = (performance.equivalentLotCount + lotCountROI - lotCount) / (lotCountClosing - lotCount) * lot.value;
  
  if(performanceValue > 0 && lotCountForPoolNumberAndLotNumber(lot.poolNumber, lot.startNumber) > lotCountForPoolNumberAndLotNumber(2, 55405)) {
    performanceValue = performanceValue - (lot.value / 100) * 18;
  }
  
  return performanceValue;
}

function lotCountROIFromDate(fromDateISOString, rois) {
  return rois.reduce((sumAmountUSDT, currentROI) => sumAmountUSDT + (fromDateISOString >= currentROI.date ? currentROI.amountUSDT : 0), 0) / GPConstants.lotUSDTValue;
}

function lotCountForPoolNumberAndLotNumber(poolNumber, lotNumber) {
  let lotCount = 0;
  
  for(let i = 0; i < poolNumber; i++) {
    if(poolNumber > i + 1) {
      lotCount += amountOfLotsInPoolNumber(poolNumber - i - 1);
    }
    else {
      lotCount += lotNumber;
    }
  }
  
  return lotCount;
}

function amountOfLotsInPoolNumber(poolNumber) {
  if(poolNumber < 1) { return 0; }
  return parseInt(GPConstants.lotCount * (GPConstants.nextMultiplicator ** (parseInt(poolNumber, 10) - 1)), 10);
}

function isClosedLotOrCommission(lotOrCommission, performance, rois) {
  if(!lotOrCommission?.poolNumber || !lotOrCommission.endNumber) { throw Error("The lot or commission is invalid, cannot determine if closed."); }
  if(!performance) { return false; }
  const lotOrCommissionClosingCount = lotCountForPoolNumberAndLotNumber(lotOrCommission.poolNumber + 1, lotOrCommission.endNumber * GPConstants.nextMultiplicator);
  return performance.equivalentLotCount + lotCountROIFromDate(lotOrCommission.date, rois) >= lotOrCommissionClosingCount;
}

function lotDescription(lot) {
  return <span><b>P{convertToRoman(lot.poolNumber)}</b>&nbsp;{lotVerticalName(lot)}&nbsp;({lotVerticaSymbol(lot)}) {lot.startNumber}&nbsp;-&nbsp;{lot.endNumber - lot.startNumber + 1}</span>;
}

function lotVerticalName(lot) {
  const verticalNames = ["alpha", "beta", "gamma", "delta", "epsilon", "zeta", "eta", "theta", "iota", "kappa"];
  const startVerticalIndex = parseInt((lot.startNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const endVerticalIndex = parseInt((lot.endNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const startVerticalName = verticalNames[startVerticalIndex];
  const endVerticalName = verticalNames[endVerticalIndex];
  
  return startVerticalName === endVerticalName ? startVerticalName : (startVerticalName + "\u00a0-\u00a0" + endVerticalName);
}

function lotVerticaSymbol(lot) {
  const verticalSymbols = ["α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ"];
  const startVerticalIndex = parseInt((lot.startNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const endVerticalIndex = parseInt((lot.endNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const startVerticalSymbol = verticalSymbols[startVerticalIndex];
  const endVerticalSymbol = verticalSymbols[endVerticalIndex];
  
  return startVerticalSymbol === endVerticalSymbol ? startVerticalSymbol : (startVerticalSymbol + "\u00a0-\u00a0" + endVerticalSymbol);
}

function lotDateTime(lot) {
  const date = new Date(lot.date);
  return "⧗\u00a0" + 
    date.getFullYear() + 
    (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) +
    (date.getDate() < 10 ? "0" : "") + date.getDate() + " " +
    (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
    (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
}

/*
Α α alpha, άλφα
Β β beta, βήτα
Γ γ gamma, γάμμα
Δ δ delta, δέλτα
Ε ε epsilon, έψιλον
Ζ ζ zeta, ζήτα
Η η eta, ήτα
Θ θ theta, θήτα
Ι ι iota, ιώτα
Κ κ kappa, κάππα
*/

function convertToRoman(num) {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  var str = '';

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
}

function localeParseFloat(s) {
    let [,thousandsSeparator,,,,decimalSeparator] = 1111.1.toLocaleString();
    s = Array.from(s, c => c === thousandsSeparator ? "" 
                         : c === decimalSeparator   ? "." : c).join("");
    return parseFloat(s);
}

const userShows = ["name", "address-street", "address-zipcode", "address-city", "address-country", "email", "phone", "bank", "iban", "enter-wallet", "growthpool"]; 
const cryptoAddressesShows = ["wallets", "enter-withdrawal", "growthpool"];
const performanceAndLotsShows = ["balances", "incoming", "outgoing", "lot", "gain", "performance", "commission", "commissions-c1", "commissions-c2", "available", "withdrawable", "withdraw", "withdrawed", "enter-withdrawal", "lots-active", "lots-passed", "lots-active-count", "lots-passed-count"]; 
const transactionsCommisionsShows = ["balances", "incoming", "outgoing", "lot", "gain", "performance", "commission", "commissions-c1", "commissions-c2", "commissions-c3", "available", "withdrawable", "withdraw", "withdrawed", "enter-withdrawal"]; 
const withdrawalsShows = ["balances", "incoming", "outgoing", "lot", "gain", "performance", "commission", "available", "withdrawable", "withdraw", "withdrawed", "enter-withdrawal"];

export default function GPUser({show, label, ...props}) {
  const [inputTextFieldValue, setInputTextFieldValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [cryptoAddresses, setCryptoAddresses] = useState(null);
  const [cryptoTransactions, setCryptoTransactions] = useState(null);
  const [mountainwolfTransactions, setMountainwolfTransactions] = useState(null);
  const [commissions, setCommissions] = useState(null);
  const [lots, setLots] = useState(null);
  const [performance, setPerformance] = useState(0);
  const [withdrawals, setWithdrawals] = useState(null);
  const [rois, setROIs] = useState(null);
  const [selectedCryptoAddress, setSelectedCryptoAddress] = useState(null);

  useEffect(() => {
    const subscriptions = [];
    
    if(withdrawalsShows.includes(show)) {
      subscriptions.push(DataStore.observeQuery(Withdrawal).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setWithdrawals(snapshot.items);
          }
          else {
            setWithdrawals([]);
          }
      }));
    }

    if(performanceAndLotsShows.includes(show)) {
      subscriptions.push(DataStore.observeQuery(PublicComponent, c => c.name.eq("performance")).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            let performance = 0;
            
            try {
              performance = JSON.parse(snapshot.items[0].content);
            } catch (e) { console.error(e, snapshot.items[0].content)}
            
            setPerformance(performance);
          }
      }));
      
      subscriptions.push(DataStore.observeQuery(Lot, Predicates.ALL, { sort: lot => lot.date(SortDirection.DESCENDING) }).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setLots(snapshot.items);
          }
          else {
            setLots([]);
          }
      }));
      
      subscriptions.push(DataStore.observeQuery(ReturnOnInvestment, Predicates.ALL, { sort: roi => roi.date(SortDirection.ASCENDING) }).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setROIs(snapshot.items);
          }
          else {
            setROIs([]);
          }
      }));
    }

    if(cryptoAddressesShows.includes(show)) {
      subscriptions.push(DataStore.observeQuery(CryptoAddress).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setCryptoAddresses(snapshot.items);
          }
          else {
            setCryptoAddresses([]);
          }
      }));
    }

    if(transactionsCommisionsShows.includes(show)) {
      subscriptions.push(DataStore.observeQuery(CryptoTransaction).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setCryptoTransactions(snapshot.items);
          }
          else {
            setCryptoTransactions([]);
          }
      }));

      subscriptions.push(DataStore.observeQuery(MountainwolfTransaction).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setMountainwolfTransactions(snapshot.items);
          }
          else {
            setMountainwolfTransactions([]);
          }
      }));
      
      subscriptions.push(DataStore.observeQuery(Commission).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setCommissions(snapshot.items);
          }
          else {
            setCommissions([]);
          }
      }));
    }
    
    if(userShows.includes(show)) {
      subscriptions.push(DataStore.observeQuery(User).subscribe(
        snapshot => {
          if(snapshot.items.length && snapshot.isSynced) {
            setUser(snapshot.items[0]);
          }
      }));
    }

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [show]);
  
  
  if(userShows.includes(show) && !user) {
    return null;
  }

  if(cryptoAddressesShows.includes(show) && !cryptoAddresses) {
    return null;
  }
  
  if(performanceAndLotsShows.includes(show) && (!performance || !lots || !rois)) {
    return null;
  }
  
  if(transactionsCommisionsShows.includes(show) && (!cryptoTransactions || !mountainwolfTransactions || !commissions)) {
    return null;
  }
  
  if(withdrawalsShows.includes(show) && !withdrawals) {
    return null;
  }

  switch (show) {
    case "wallets":
      if(cryptoAddresses?.length) {
        return (
          <> {
            cryptoAddresses.map((cryptoAddress) => {
              return (
                <TextField
                  key= {cryptoAddress.address}
                  size="small"
                  value= {cryptoAddress.address}
                  isReadOnly={true}
                  variation="quiet"
                  innerEndComponent={
                    <Button
                      size="small"
                      ariaLabel="Remove Growthpool TRON wallet address."
                      variation="link"
                      isLoading={buttonLoading}
                      loadingText=" "
                      onClick={(e) => {
                        e.preventDefault();
                        removeWalletAddress(cryptoAddress.address, setButtonLoading, setErrorMessage);
                      }}
                    >
                      <GPImage style={{width: 24, height: 24}} imgKey="icon-delete-wallet.svg"/>
                    </Button>
                  }
                />
              );
            })
          } </>
        );
      }
      else {
        return (
          <TextField
            size="small"
            variation="quiet"
            isReadOnly={true}
            placeholder="T?????????????????????????????????"
          />
        );
      }
    case "enter-wallet":
      return(
        <TextField
          size="small"
          placeholder="Txxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
          hasError={ errorMessage ? true : false }
          errorMessage={ errorMessage ? errorMessage : null }
          label={label} 
          onChange={(e) => {
            setInputTextFieldValue(e.target.value);
            setErrorMessage(null);
          }}
          value={inputTextFieldValue}
          innerEndComponent={
            <Button
              size="small"
              ariaLabel={label}
              variation="link"
              isLoading={buttonLoading}
              loadingText=" "
              onClick={(e) => {
                e.preventDefault();
                addWalletAddress(inputTextFieldValue, setInputTextFieldValue, setButtonLoading, setErrorMessage);
              }}
            >
              <GPImage style={{width: 24, height: 24}} imgKey="icon-enter-wallet.svg"/>
            </Button>
          }
        />
      );
    case "growthpool":
      return cryptoAddresses?.length ? (
        <TextField
          size="small"
          value={user.growthpoolTRONReceivingAddress}
          isReadOnly={true}
          variation="quiet"
          innerEndComponent={
            <Button
              size="small"
              ariaLabel="Copy Growthpool TRON wallet address to clipborad."
              variation="link"
              isLoading={buttonLoading}
              loadingText=" "
              onClick={(e) => {
                e.preventDefault();
                setButtonLoading(true);
                window.navigator.clipboard.writeText(user.growthpoolTRONReceivingAddress);
                setTimeout(() => setButtonLoading(false));
              }}
            >
              <GPImage style={{width: 24, height: 24}} imgKey="icon-copy.svg"/>
            </Button>
          }
        />
      ) : null;
    case "balances":
    case "incoming": 
    case "outgoing": 
    case "lot": 
    case "gain": 
    case "performance": 
    case "commission": 
    case "available":
    case "withdrawable":
    case "withdraw":
    case "withdrawed":
    case "enter-withdrawal": {
      const balances = calculateAllTronUSDTBalances(cryptoTransactions, mountainwolfTransactions, lots, commissions, withdrawals, performance, rois);
      if(show === "balances") {
        return (
          <Table size="large">
            <TableBody>
            {Object.keys(balances).map(key => {
              return (
                <TableRow key={show + Counter.next().value}>
                  <TableCell key={show + Counter.next().value} textAlign="left">{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
                  <TableCell key={show + Counter.next().value} textAlign="right">{balances[key].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                </TableRow>
              );
            })}
            </TableBody>
          </Table>
        );
      }
      else if(show === "enter-withdrawal") {
        return(
          <>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell textAlign="left">Withdrawable amount</TableCell>
                  <TableCell textAlign="right">{balances["withdrawable"].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="left">Withdrawed amount</TableCell>
                  <TableCell textAlign="right">{balances["withdrawed"].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell textAlign="left">Pending amount</TableCell>
                  <TableCell textAlign="right">{balances["withdraw"].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br/>
            <SelectField
              label="Withdrawal wallet address"
              labelHidden
              placeholder="Select your withdrawal wallet address."
              value={selectedCryptoAddress ? selectedCryptoAddress : ""}
              onChange={(e) => {
                setSelectedCryptoAddress(e.target.value);
                setErrorMessage(null);
              }}
            >
              { cryptoAddresses.map((cryptoAddress) => <option key={show + Counter.next().value} value={cryptoAddress.address}>{cryptoAddress.address}</option>) }
            </SelectField>
            <TextField
              size="small"
              placeholder={100.00.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2})}
              hasError={ errorMessage ? true : false }
              errorMessage={ errorMessage ? errorMessage : null }
              label={label} 
              onChange={(e) => {
                setInputTextFieldValue(e.target.value);
                setErrorMessage(null);
              }}
              value={inputTextFieldValue}
              innerEndComponent={
                <Button
                  size="small"
                  ariaLabel={label}
                  variation="link"
                  isLoading={buttonLoading}
                  loadingText=" "
                  onClick={(e) => {
                    e.preventDefault();
                    const withdrawalAmount = localeParseFloat(inputTextFieldValue.trim());
                    
                    if(isNaN(withdrawalAmount)) {
                      setInputTextFieldValue("");
                      return;
                    }
                    
                    setInputTextFieldValue(withdrawalAmount.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}));

                    if(!selectedCryptoAddress) {
                      setErrorMessage("Please select a wallet address.");
                    }
                    else if(balances.lot < GPConstants.lotUSDTValue) {
                      setErrorMessage("You need to have a least one lot.");
                    }
                    else if(withdrawalAmount < 100) {
                      setErrorMessage("Minimum amount is " + (100).toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT.");
                    }
                    else if(withdrawalAmount > 50000) {
                      setErrorMessage("Maximum amount is " + (50000).toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT.");
                    }
                    else if(withdrawalAmount > balances.withdrawable) {
                      if(balances.withdrawable >= 100) {
                        setErrorMessage("Withdrawable amount is " + balances.withdrawable.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT.");
                      }
                      else {
                        setErrorMessage("Minimum amount is " + (100).toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT, but your withdrawble amount is only " + balances.withdrawable.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT.");
                      }
                    }
                    else if(window.confirm("Widthdraw " + withdrawalAmount.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT to wallet address " + selectedCryptoAddress + "?")) {
                      withdrawUSDTAmountToAddress(withdrawalAmount, selectedCryptoAddress, setInputTextFieldValue, setButtonLoading, setErrorMessage);
                    }
                  }}
                >
                  <GPImage style={{width: 24, height: 24}} imgKey="icon-enter-wallet.svg"/>
                </Button>
              }
            />
          </>
        );
      }
      return (<span>{balances[show].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</span>);
    }
    case "lots-active":
    case "lots-passed":
      return (
        <div className="lots-list">
          {lots.filter((lot) => {
            if(show === "lots-passed") {
              return performanceValueForLot(lot, performance, rois) >= lot.value; //lotCountForPoolNumberAndLotNumber(lot.poolNumber, lot.endNumber) >= performance.lotCount;
            }
            else {
              return performanceValueForLot(lot, performance, rois) < lot.value; //lotCountForPoolNumberAndLotNumber(lot.poolNumber, lot.endNumber) < performance.lotCount;
            }
          }).map(lot => {
            return (
              <div key={show + Counter.next().value} className="lots-list-row">
                <div className="lots-list-cell" key={show + Counter.next().value}><GPImage imgKey="icon-lots-lot.svg"/></div>
                <div className="lots-list-cell" key={show + Counter.next().value}>{lot.endNumber - lot.startNumber + 1} Lots</div>
                <div className="lots-list-cell" key={show + Counter.next().value}>{lotDateTime(lot)}</div>
                <div className="lots-list-cell" key={show + Counter.next().value}>{lot.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} USDT</div>
                <div className="lots-list-cell" key={show + Counter.next().value}>{lotDescription(lot)}</div>
                <div className="lots-list-cell" key={show + Counter.next().value}><b>{(performanceValueForLot(lot, performance, rois) / lot.value * 100).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</b></div>
                <div className="lots-list-cell" key={show + Counter.next().value}><b>{(performanceValueForLot(lot, performance, rois) + lot.value).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</b></div>
              </div>
            );
          })}
        </div>
      );
    case "lots-active-count":
    case "lots-passed-count":
      const filterredLots = lots.filter((lot) => {
        if(show === "lots-passed-count") {
          return performanceValueForLot(lot, performance, rois) >= lot.value; //lotCountForPoolNumberAndLotNumber(lot.poolNumber, lot.endNumber) >= performance.lotCount;
        }
        else {
          return performanceValueForLot(lot, performance, rois) < lot.value; //lotCountForPoolNumberAndLotNumber(lot.poolNumber, lot.endNumber) < performance.lotCount;
        }
      });
      let lotCount = 0;
      
      filterredLots.forEach((lot) => {
        lotCount += lot.endNumber - lot.startNumber + 1;
      });
      return lotCount;
    case "commissions-c1":
    case "commissions-c2":
    case "commissions-c3": // TODO
      const perLevelCommissions = [0, 0, 0, 0, 0];
      if(commissions?.length) {
        commissions.forEach((commission) => {
          if(show === "commissions-c1") {
            perLevelCommissions[commission.level - 1] += commission.value;
          }
          else if(show === "commissions-c2" && isClosedLotOrCommission(commission, performance, rois)) {
            perLevelCommissions[commission.level - 1] += commission.value;
          }
        });
      }
      return (
        <div className={show + "-list"}>
          <div className={show + "-level"}>Direct:&nbsp;{(perLevelCommissions[0] / GPConstants.commissionLevelPercentages[0]).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;x&nbsp;{GPConstants.commissionLevelPercentages[0] * 100}%&nbsp;=&nbsp;{perLevelCommissions[0].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</div>
          <div className={show + "-level"}>Level&nbsp;2:&nbsp;{(perLevelCommissions[1] / GPConstants.commissionLevelPercentages[1]).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;x&nbsp;{GPConstants.commissionLevelPercentages[1] * 100}%&nbsp;=&nbsp;{perLevelCommissions[1].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</div>
          <div className={show + "-level"}>Level&nbsp;3:&nbsp;{(perLevelCommissions[2] / GPConstants.commissionLevelPercentages[2]).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;x&nbsp;{GPConstants.commissionLevelPercentages[2] * 100}%&nbsp;=&nbsp;{perLevelCommissions[2].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</div>
          <div className={show + "-level"}>Level&nbsp;4:&nbsp;{(perLevelCommissions[3] / GPConstants.commissionLevelPercentages[3]).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;x&nbsp;{GPConstants.commissionLevelPercentages[3] * 100}%&nbsp;=&nbsp;{perLevelCommissions[3].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</div>
          <div className={show + "-level"}>Level&nbsp;5:&nbsp;{(perLevelCommissions[4] / GPConstants.commissionLevelPercentages[4]).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;x&nbsp;{GPConstants.commissionLevelPercentages[4] * 100}%&nbsp;=&nbsp;{perLevelCommissions[4].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</div>
        </div>
      );
    case "name":
      return(user.name ? user.name : "");
    case "address-street":
      return(user.addressStreetNameHouseNumber ? user.addressStreetNameHouseNumber : "");
    case "address-zipcode":
      return(user.addressZipCode ? user.addressZipCode : "");
    case "address-city":
      return(user.addressCity ? user.addressCity : "");
    case "address-country":
      return(user.addressCountry ? user.addressCountry : "");
    case "email":
      return(user.email ? user.email : "");
    case "phone":
      return(user.phone ? user.phone : "");
    case "bank":
      return(user.bankName) ? user.bankName : "";
    case "iban":
      return(user.bankIban ? user.bankIban : "");
    default:
      return null;
  }
}

/*

wallet
	cryptoAddresses
balances
incoming 
outgoing 
lot 
gain 
performance
available
	cryptoAddresses
	cryptoTransactions
	mountainwolfTransactions
	lots
	commissions
	performance
lots-active
lots-passed
lots-active-count
lots-passed-count
	lots
	performance
name
address-street
address-zipcode
address-city
address-country
email
phone
bank
iban
enter-wallet
growthpool
	user
	
	*/