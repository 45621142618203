import React from 'react';

import ApplicationContext from './ApplicationContext';
import DBComponent from './DBComponent';

export default class GPApplication extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      cognitoUser: null,
      setCognitoUser: this.setCognitoUser.bind(this),
    };
  }
  
  async setCognitoUser(cognitoUser) {
    this.setState(state => ({
      cognitoUser: cognitoUser
    }));
  }

  render() {
    return (
      <ApplicationContext.Provider value={this.state}>
        <DBComponent placeholder className="start-placeholder" height="100%" />
      </ApplicationContext.Provider>
    );
  }
}