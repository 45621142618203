import { Storage } from 'aws-amplify';
import { useState, useEffect } from 'react';

export default function GPImage({imgKey, ...props}) {
  const [imageSRC, setImageSRC] = useState(null);
  
  useEffect(() => {
    const createImageSRC = async function() {
      setImageSRC(await Storage.get(imgKey, {
        expires: 24 * 60 * 60 // validity of the URL, in seconds. defaults to 900 (15 minutes)
      }));
    };
    
    createImageSRC();
    
  }, [imgKey]);
  
  return imageSRC ? <img alt={imgKey} {...props} src={imageSRC} /> : null;
}