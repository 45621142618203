import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Amplify, AuthModeStrategyType, Logger } from 'aws-amplify';
import { Placeholder } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme
import reportWebVitals from './reportWebVitals';
import awsconfig from './aws-exports';
import GPApplication from './GPApplication';

Logger.LOG_LEVEL = 'ERROR'; // 'VERBOSE', 'DEBUG', 'INFO', 'WARN', 'ERROR'

window.localStorage.clear(); // This is needed, due to a bug with credentials stored, GraphQL is not populated after a change from unauthenticated to autehticated creadentials. Althoug you need only to remove the "LastAuthUser" key, it seems more save to clear all credentials.
//const localStorageKey = 'CognitoIdentityServiceProvider.' + awsconfig.aws_user_pools_web_client_id + '.LastAuthUser';
//window.localStorage.removeItem(localStorageKey); // Hack to allow relogin, related to PublicComponents not available after logout and DataStore.clear().

async function startApplication() {
  await Amplify.DataStore.start();
  setTimeout(async () => { // Hack to prevent Exception in clear.
    await Amplify.DataStore.clear();
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <GPApplication/>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root')
    );
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
}

ReactDOM.render(
  <>
    <style>{`
      html, body {
        width: 100%;
        height: 100%;
        margin: 0;
      } 

      #root {
        width: 100%;
        height: 100%;
      } 
      
      .start-placeholder {
        border-radius: 0;
        --amplify-components-placeholder-start-color: #8b79f1;
        --amplify-components-placeholder-end-color: #6b59d1;
      }
    `}
    </style>
    <Placeholder className="start-placeholder" height="100%" width="100%" />
  </>,
  document.getElementById('root'));

Amplify.configure({
  ...awsconfig,
  DataStore: { authModeStrategyType: AuthModeStrategyType.MULTI_AUTH }
});

startApplication();