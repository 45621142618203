import { useContext, useState, useEffect} from 'react';
import { Amplify, Hub, API, Auth, DataStore } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import ApplicationContext from './ApplicationContext';
import DBComponent from './DBComponent';
import { PublicComponent } from './models';

const GPAComponents = {
  SignIn:{
    Header: () => (
      <div style={{color: "var(--growthpool-color-0)", textAlign: "center", fontSize: "2em", paddingTop: "1em"}}>
        <strong>GROWTH</strong>POOL
      </div>
    )
  }
};

const GPAServices = {
  async handleSignIn({username, password}) {
    let exception;
    let signInResult;
    
    try { 
      signInResult = await Auth.signIn({username, password});
      return signInResult;
    }
    catch(e) {
      switch(e.name) {
        case "UserNotFoundException":
          exception = e;
        break;
        default:
          throw e;
      } 
    }

    const result = await API.post("growthpoolREST", "/signUp", { body: { email: username, password: password } });

    if(result === false) {
      throw exception; 
    }
    else if(result !== true) {
      throw Error(result);
    }

    signInResult = await Auth.signIn(username, password);
    return signInResult;
  }
};

export default function GPAuthenticator({logout, services, components, hideSignUp, children, ...props}) {
  const [ maintenance, setMaintenance ] = useState(null);
  const { cognitoUser, setCognitoUser } = useContext(ApplicationContext);

  useEffect(() => {
    const subscriptions = [];
    subscriptions.push(DataStore.observeQuery(PublicComponent, c => c.name.eq("maintenance")).subscribe(
      snapshot => {
        if(snapshot.isSynced) {
          if(!snapshot.items[0] && maintenance) {
            setMaintenance(false);
          }
          else if(snapshot.items[0] && snapshot.items[0].enabled !== maintenance) {
            if(snapshot.items[0].enabled && cognitoUser) {
              //window.location = "/";
              //setTimeout(() => Auth.signOut());
              Auth.signOut();
              window.location = "/";
            }
            setMaintenance(snapshot.items[0].enabled);
          }
        }
      }
    ));
    
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [maintenance, cognitoUser, props]);
  
  useEffect(() => {
    return Hub.listen('auth', async ({ payload: { event, data } }) => { 
    switch (event) {
      case 'signIn': // This is to make the DataStore fetch again with authenticated credentials, otherwise only publicly available data is fetched.
        await Amplify.DataStore.stop();
        setCognitoUser(data);
        break;
      case 'signOut': // This is to delete all data after a logout for security reasons.
        await Amplify.DataStore.clear().then(() => window.localStorage.clear());
        setCognitoUser(null);
        break;
      default:
    }
  });

  }, [cognitoUser, setCognitoUser]);
    
  if(logout) {
    window.location = "/";
    Auth.signOut();
    return null;
  }
  
  if(maintenance === null) {
    return null;
  }
  
  if(maintenance === true) {
    return (<DBComponent name="maintenance"/>);
  }
  
  return(
    <Authenticator services={GPAServices} components={GPAComponents} hideSignUp={true} {...props}>
    {(signOut, user) => {
      return (children);
    }}
    </Authenticator>
  );
}

