import React from 'react';

const ApplicationContext = React.createContext({
  cognitoUser: null,
  setCognitoUser: () => {}
});

export default ApplicationContext;

function* ObjectIDCounter() {
  let index = 0;
  while (true) {
      yield index++;
  }
}

export const Counter = ObjectIDCounter();
