import { useContext, useState, useEffect } from 'react';
import { DataStore, /*SortDirection , Predicates, syncExpression */ } from 'aws-amplify';
import * as AmplifyUI from '@aws-amplify/ui-react';
import { Placeholder } from '@aws-amplify/ui-react';

import { Routes, Route, Link as RouterLink, NavLink as RouterNavLink, Navigate, Outlet } from 'react-router-dom';

import JSXParser from 'react-jsx-parser';

import ApplicationContext from './ApplicationContext';
import GPAuthenticator from './GPAuthenticator';
import GPUser from './GPUser';
import GPGauge from './GPGauge';
import GPImage from './GPImage';
import GPAdmin from './GPAdmin';
import { PublicComponent, AdminComponent } from './models';

const allowedReactComponents = {GPAdmin, GPGauge, GPImage, GPUser, GPAuthenticator, Routes, Route, RouterLink, RouterNavLink, Navigate, Outlet, ...AmplifyUI}; // <--- Add here new allowed components in db content

/*
function typeNameOfDataStoreObject(dbObject) {
  return dbObject?.__proto__?.constructor?.name;
}
*/

export default function DBComponent({name, log, placeholder, ...props}) {
  const [publicComponent, setPublicComponent] = useState(null);
  const [adminComponent, setAdminComponent] = useState(null);
  const [component, setComponent] = useState(null);
  const { cognitoUser } = useContext(ApplicationContext);
  
   useEffect(() => {
    const subscriptions = [];
    subscriptions.push(DataStore.observeQuery(PublicComponent, c => c.name.eq(name ? name : null)).subscribe(
      snapshot => {
        if(snapshot.isSynced && ((!publicComponent && snapshot.items[0]) || (publicComponent && !snapshot.items[0]) || (publicComponent && snapshot.items[0] && publicComponent.id !== snapshot.items[0].id) || (publicComponent && snapshot.items[0] && publicComponent._version < snapshot.items[0]._version))) {
          setPublicComponent(snapshot.items[0]);
        }
      }
    ));
    
    subscriptions.push(DataStore.observeQuery(AdminComponent, c => c.name.eq(name ? name : null)).subscribe(
      snapshot => {
        if(snapshot.isSynced && ((!adminComponent && snapshot.items[0]) || (adminComponent && !snapshot.items[0]) || (adminComponent && snapshot.items[0] && adminComponent.id !== snapshot.items[0].id) || (adminComponent && snapshot.items[0] && adminComponent._version < snapshot.items[0]._version))) {
          setAdminComponent(snapshot.items[0]);
        }
      }
    ));
    
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [cognitoUser, component, publicComponent, adminComponent, name, log, props]);
  
  if(!component && publicComponent && !adminComponent) {
    setComponent(publicComponent);
  }
  else if(!component && adminComponent) {
    setComponent(adminComponent);
  }
  else if(component && adminComponent && (component.id !== adminComponent.id || component._version < adminComponent._version)) {
    setComponent(adminComponent);
  }
  else if(component && !adminComponent && publicComponent && (component._version < publicComponent._version)) {
    setComponent(publicComponent);
  }
  else if(component && !publicComponent && !adminComponent) {
    setComponent(null);
  }

  if(!component || component.enabled === false) {
    return placeholder ? (<Placeholder className={props.className} width={props.width} height={props.height} />) : null;
  }
  
  return (
    <>
      {component.style ? <style>{component.style}</style> : ""}
       <JSXParser showWarnings={true} renderInWrapper={false} components={{DBComponent, ...allowedReactComponents}} jsx={component.content}/>
    </>
  );
}