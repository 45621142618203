import { API } from 'aws-amplify';
import { TextField, Button, Table, TableBody, TableRow, TableCell, StepperField } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';

import { Card } from '@aws-amplify/ui-react';

import { Counter } from './ApplicationContext';

import GPImage from './GPImage';

const GPConstants = {
  tronUSDTContractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  startDateISOString: "2021-12-01T00:00:00.000Z",
  startNumber: 1,
  nextMultiplicator: 6,
  lotStartNumber: 1,
  lotCount: 50000,
  lotUSDTValue: 100.0,
  agio: 0.05,
  commissionLevelPercentages: [ 0.1, 0.04, 0.03, 0.02, 0.01 ]
};

async function fetchRESTAPI(apiName, apiPath, postData, setButtonLoading, setErrorMessage) {
  //console.log(apiName + ":", postData);
  if(setButtonLoading) { setButtonLoading(true) }

  let result;
  let error;
  
  try {
    result = await API.post(apiName, apiPath, postData);
  } catch (e) {
    error = e;
  }
  
  //console.log(apiName + " result:", result);
  if(setButtonLoading) { setButtonLoading(false); }
  
  if(!result?.success && result?.message) {
    setErrorMessage(result.message);
  }
  
  if(error) { throw error; }
  
  return result;
}

async function fetchCalculatedBalancesOfOwner(email, setButtonLoading, setErrorMessage) {
  try {
    const postData = { body: { userDataOperation: "calculateBalancesOfOwner", userDataParameters: email} };
    return await fetchRESTAPI("growthpoolREST", "/userData", postData, setButtonLoading, setErrorMessage);
  }
  catch(e) {
    console.error("Balances could not be calculated, exception:", e);
    setErrorMessage("Balances could not be calculated, due to an error.");
  }
}

async function dryRunAttachTransactionToOwner(email, transactionHash, setButtonLoading, setErrorMessage) {
  try {
    const postData = {
      body: {
        userDataOperation: "attachTransactionToOwner",
        userDataParameters: {
          dryRun: true,
          email: email,
          transactionHash: transactionHash
        }
      }
    };
    return await fetchRESTAPI("growthpoolREST", "/userData", postData, setButtonLoading, setErrorMessage);
  }
  catch(e) {
    console.error("Could not dry run attach transaction to owner, exception:", e);
    setErrorMessage("Could not dry run attach transaction to owner, due to an error.");
  }
}

async function attachTransactionToOwner(email, transactionHash, operationHash, setButtonLoading, setErrorMessage) {
  try {
    const postData = {
      body: {
        userDataOperation: "attachTransactionToOwner",
        userDataParameters: {
          dryRun: false,
          email: email,
          transactionHash: transactionHash,
          operationHash: operationHash
        }
      }
    };
    return await fetchRESTAPI("growthpoolREST", "/userData", postData, setButtonLoading, setErrorMessage);
  }
  catch(e) {
    console.error("Could not attach transaction to owner, exception:", e);
    setErrorMessage("Could not attach transaction to owner, due to an error.");
  }
}

async function fetchNextPendingWithdrawal(index, setErrorMessage) {
  try {
    const postData = {
      body: {
        userDataOperation: "nextPendingWithdrawal",
        userDataParameters: { index: index }
      }
    };
    return await fetchRESTAPI("growthpoolREST", "/userData", postData, null, setErrorMessage);
  }
  catch(e) {
    console.error("Could not dry run attach transaction to owner, exception:", e);
    setErrorMessage("Could not dry run attach transaction to owner, due to an error.");
  }
}

async function updatePendingWithdrawal(withdrawal, index, setButtonLoading, setErrorMessage) {
  try {
    const postData = {
      body: {
        userDataOperation: "updatePendingWithdrawal",
        userDataParameters: { withdrawal: withdrawal, index: index }
      }
    };
    return await fetchRESTAPI("growthpoolREST", "/userData", postData, setButtonLoading, setErrorMessage);
  }
  catch(e) {
    console.error("Could not update the withdrawal request, exception:", e);
    setErrorMessage("Could not update the withdrawal request, due to an error.");
  }
}

async function deletePendingWithdrawal(withdrawal, index, setButtonLoading, setErrorMessage) {
  try {
    const postData = {
      body: {
        userDataOperation: "deletePendingWithdrawal",
        userDataParameters: { withdrawal: withdrawal, index: index }
      }
    };
    return await fetchRESTAPI("growthpoolREST", "/userData", postData, setButtonLoading, setErrorMessage);
  }
  catch(e) {
    console.error("Could not delete the withdrawal, exception:", e);
    setErrorMessage("Could not delete the withdrawal, due to an error.");
  }
}

function amountOfLotsInPoolNumber(poolNumber) {
  if(poolNumber < 1) { return 0; }
  return parseInt(GPConstants.lotCount * (GPConstants.nextMultiplicator ** (parseInt(poolNumber, 10) - 1)), 10);
}

function lotDescription(lot) {
  return <span><b>P{convertToRoman(lot.poolNumber)}</b>&nbsp;{lotVerticalName(lot)}&nbsp;({lotVerticaSymbol(lot)}) {lot.startNumber}&nbsp;-&nbsp;{lot.endNumber - lot.startNumber + 1}</span>;
}

function lotVerticalName(lot) {
  const verticalNames = ["alpha", "beta", "gamma", "delta", "epsilon", "zeta", "eta", "theta", "iota", "kappa"];
  const startVerticalIndex = parseInt((lot.startNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const endVerticalIndex = parseInt((lot.endNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const startVerticalName = verticalNames[startVerticalIndex];
  const endVerticalName = verticalNames[endVerticalIndex];
  
  return startVerticalName === endVerticalName ? startVerticalName : (startVerticalName + "\u00a0-\u00a0" + endVerticalName);
}

function lotVerticaSymbol(lot) {
  const verticalSymbols = ["α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ"];
  const startVerticalIndex = parseInt((lot.startNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const endVerticalIndex = parseInt((lot.endNumber - 1) / (amountOfLotsInPoolNumber(lot.poolNumber) / 10), 10);
  const startVerticalSymbol = verticalSymbols[startVerticalIndex];
  const endVerticalSymbol = verticalSymbols[endVerticalIndex];
  
  return startVerticalSymbol === endVerticalSymbol ? startVerticalSymbol : (startVerticalSymbol + "\u00a0-\u00a0" + endVerticalSymbol);
}

function prettyGPDateFromISODateString(isoDateString) {
  if(!isoDateString) return "";
  const date = new Date(isoDateString);
  return "⧗\u00a0" + 
    date.getFullYear() + 
    (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) +
    (date.getDate() < 10 ? "0" : "") + date.getDate() + " " +
    (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
    (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
}

/*
Α α alpha, άλφα
Β β beta, βήτα
Γ γ gamma, γάμμα
Δ δ delta, δέλτα
Ε ε epsilon, έψιλον
Ζ ζ zeta, ζήτα
Η η eta, ήτα
Θ θ theta, θήτα
Ι ι iota, ιώτα
Κ κ kappa, κάππα
*/

function convertToRoman(num) {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  var str = '';

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
}

function isTronAddress(address) {
  return typeof address === "string" && address.length === 34 && address.startsWith("T");
}

function isTronHash(hash) {
  return /^([A-Fa-f0-9]{64})$/.test(hash);
}

function tronscanLink(string) {
  let queryType;
  if(isTronAddress(string)) { queryType = "address" }
  else if(isTronHash(string)) { queryType = "transaction" }
  else { return ""; } 
  return <a target="_blank" rel="noreferrer" href={"https://tronscan.org/#/" + queryType + "/" + string}>{string}</a>;
}

export default function GPAdmin({show, ownerLabel, emailLabel, transactionHashLabel, joinAmountLabel, ...props}) {
  const [owner, setOwner] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [balances, setBalances] = useState(null);
  const [email, setEmail] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [attachTransactionResult, setAttachTransactionResult] = useState(null);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [nextPendingWithdrawalResult, setNextPendingWithdrawalResult] = useState(null);
  const [withdrawalIndex, setWithdrawalIndex] = useState(0);
  const [updateWithdrawal, setUpdateWithdrawal] = useState(null);
  const [deleteWithdrawal, setDeleteWithdrawal] = useState(null);

  useEffect(() => {
    if(show === "balances" && owner && buttonPressed) {
      setButtonPressed(false);
      fetchCalculatedBalancesOfOwner(owner, setButtonLoading, setErrorMessage).then((result) => {
        setBalances(result);
      });
    }
  }, [show, owner, buttonPressed]);

  useEffect(() => {
    if(show === "attachTransaction" && email && transactionHash && buttonPressed) {
      setButtonPressed(false);
      dryRunAttachTransactionToOwner(email, transactionHash, setButtonLoading, setErrorMessage).then((result) => {
        setAttachTransactionResult(result);
      });
    }
  }, [show, email, transactionHash, buttonPressed]);
  
  useEffect(() => {
    if(show === "withdrawal" && !nextPendingWithdrawalResult ) {
      setButtonPressed(false);
      fetchNextPendingWithdrawal(withdrawalIndex, setErrorMessage).then((result) => {
        setNextPendingWithdrawalResult(result);
        setTransactionHash("");
      });
    }
  }, [show, withdrawalIndex, nextPendingWithdrawalResult]);
  
  useEffect(() => {
    if(show === "withdrawal" && updateWithdrawal) {
      setButtonPressed(false);
      updatePendingWithdrawal(updateWithdrawal, withdrawalIndex, setButtonLoading, setErrorMessage).then((result) => {
        if(result.success) {
          const pendingCount = result.result.pendingWithdrawalsCount;
          setNextPendingWithdrawalResult(result);
          setWithdrawalIndex(withdrawalIndex + 1 > pendingCount ? pendingCount - 1 : withdrawalIndex);
          setTransactionHash("");
        }
      });
    }
    setUpdateWithdrawal(null);
  }, [show, withdrawalIndex, updateWithdrawal]);

  useEffect(() => {
    if(show === "withdrawal" && deleteWithdrawal) {
      setButtonPressed(false);
      deletePendingWithdrawal(deleteWithdrawal, withdrawalIndex, setButtonLoading, setErrorMessage).then((result) => {
        if(result.success) {
          const pendingCount = result.result.pendingWithdrawalsCount;
          setNextPendingWithdrawalResult(result);
          setWithdrawalIndex(withdrawalIndex + 1 > pendingCount ? pendingCount - 1 : withdrawalIndex);
          setTransactionHash("");
        }
      });
    }
    setDeleteWithdrawal(null);
  }, [show, withdrawalIndex, deleteWithdrawal]);
  
  switch (show) {
    case "balances":
      return(
        <>
          <Card className="white-card-admin-100">
            <TextField
              size="small"
              placeholder="Email, Wallet Address, Transaction Hash or User ID" 
              hasError={ errorMessage ? true : false }
              errorMessage={ errorMessage ? errorMessage : null }
              label={ownerLabel} 
              onChange={(e) => {
                setOwner(e.target.value?.trim());
                setBalances(null);
                setErrorMessage(null);
              }}
              onKeyUp={(e) => {
                if(e.code === "Enter" || e.code === "NumpadEnter") {
                  setButtonPressed(true);
                }
              }}
              value={owner}
              innerEndComponent={
                <Button
                  size="small"
                  ariaLabel={ownerLabel}
                  variation="link"
                  isLoading={buttonLoading}
                  loadingText=" "
                  onClick={(e) => {
                    e.preventDefault();
                    setButtonPressed(true);
                  }}
                >
                  <GPImage style={{width: 24, height: 24}} imgKey="icon-enter-wallet.svg"/>
                </Button>
              }
            />
          </Card>
          { typeof balances?.user === "object" && !Array.isArray(balances?.user) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>User</h2>
            <Table size="small">
              <TableBody><TableRow><TableCell textAlign="left">Name</TableCell><TableCell textAlign="left">{balances?.user.name}</TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Address</TableCell><TableCell textAlign="left">
                  {balances?.user.addressStreetNameHouseNumber}<br/>
                  {balances?.user.addressZipCode}&nbsp;{balances?.user.addressCity}<br/>
                  {balances?.user.addressCountry}
                </TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Phone</TableCell><TableCell textAlign="left">{balances?.user.phone}</TableCell></TableRow></TableBody>
            </Table>
          </Card> : null }
          { balances?.success && typeof balances?.balances === "object" && !Array.isArray(balances?.balances) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Account Balance</h2>
            <Table size="small">
              <TableBody>{Object.keys(balances.balances).map(key => {
                return (
                  <TableRow key={show + Counter.next().value}>
                    <TableCell key={show + Counter.next().value} textAlign="left">{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
                    <TableCell key={show + Counter.next().value} textAlign="right">{balances.balances[key].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                  </TableRow>
                );
              })}</TableBody>
            </Table>
          </Card>: null }
          { balances?.success && Array.isArray(balances?.lots) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Lots</h2>
            <div className="lots-list">
              {balances.lots.map(lot => {
                return (
                  <div key={show + Counter.next().value} className="lots-list-row">
                    <div className="lots-list-cell" key={show + Counter.next().value}><GPImage imgKey="icon-lots-lot.svg"/></div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lot.endNumber - lot.startNumber + 1} Lots</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{prettyGPDateFromISODateString(lot.date)}</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lot.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} USDT</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lotDescription(lot)}</div>
                  </div>
                );
              })}
            </div>
          </Card> : null }
          { balances?.cryptoAddresses && Array.isArray(balances?.cryptoAddresses) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Wallet Addresses</h2>
            <Table size="small">
              <TableBody>{balances.cryptoAddresses.map(cryptoAddress => {
                return (<TableRow key={show + Counter.next().value}>
                  <TableCell key={show + Counter.next().value} textAlign="right">{tronscanLink(cryptoAddress.address)}</TableCell>
                </TableRow>);
              })}</TableBody>
            </Table>
          </Card>: null }
          { balances?.withdrawals && Array.isArray(balances?.withdrawals) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Withdrawals</h2>
            <Table size="small" variation="bordered">
              <TableBody><TableRow>
                <TableCell key={show + Counter.next().value} textAlign="left"><b>State</b></TableCell>
                <TableCell key={show + Counter.next().value} textAlign="left"><b>Date</b></TableCell>
                <TableCell key={show + Counter.next().value} textAlign="left"><b>Amount</b></TableCell>
                <TableCell key={show + Counter.next().value} textAlign="left"><b>To Address</b></TableCell>
                <TableCell key={show + Counter.next().value} textAlign="left"><b>Hash</b></TableCell>
              </TableRow>
              {balances.withdrawals.sort((w1, w2) => w1.date > w2.datw).map(withdrawal => {
                return (<TableRow key={show + Counter.next().value}>
                  <TableCell key={show + Counter.next().value} textAlign="left">{withdrawal.state}</TableCell>
                  <TableCell key={show + Counter.next().value} textAlign="left">{prettyGPDateFromISODateString(withdrawal.date)}</TableCell>
                  <TableCell key={show + Counter.next().value} textAlign="right">{withdrawal.amount.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                  <TableCell key={show + Counter.next().value} textAlign="right">{tronscanLink(withdrawal.toAddress)}</TableCell>
                  <TableCell key={show + Counter.next().value} textAlign="right">{tronscanLink(withdrawal.hash)}</TableCell>
                </TableRow>);
              })}</TableBody>
            </Table>
          </Card>: null }
        </>
      );
    case "attachTransaction":
      return(
        <>
          <Card className="white-card-admin-100">
            <Table size="small">
              <TableBody><TableRow><TableCell>
                  <TextField
                    size="small"
                    placeholder="user@domain.tld"
                    hasError={ errorMessage ? true : false }
                    //errorMessage={ errorMessage ? errorMessage : null }
                    label={emailLabel} 
                    onChange={(e) => {
                      setEmail(e.target.value?.trim());
                      setErrorMessage(null);
                      setAttachTransactionResult(null);
                    }}
                    onKeyUp={(e) => {
                      if(e.code === "Enter" || e.code === "NumpadEnter") {
                        setButtonPressed(true);
                      }
                    }}
                    value={email}
                    innerEndComponent={
                      <Button
                        size="small"
                        ariaLabel={emailLabel}
                        variation="link"
                        isLoading={buttonLoading}
                        loadingText=" "
                        onClick={(e) => {
                          e.preventDefault();
                          setButtonPressed(true);
                        }}
                      >
                        <GPImage style={{width: 24, height: 24}} imgKey="icon-enter-wallet.svg"/>
                      </Button>
                    }
                  />
                </TableCell></TableRow>
                <TableRow><TableCell>
                  <TextField
                    size="small"
                    placeholder="7bf2275c1f2a1fca55a8effb372f0375f91ca51622218b9639ac7174fca08911"
                    hasError={ errorMessage ? true : false }
                    errorMessage={ errorMessage ? errorMessage : null }
                    label={transactionHashLabel} 
                    onChange={(e) => {
                      setTransactionHash(e.target.value?.trim());
                      setAttachTransactionResult(null);
                      setErrorMessage(null);
                    }}
                    onKeyUp={(e) => {
                      if(e.code === "Enter" || e.code === "NumpadEnter") {
                        setButtonPressed(true);
                      }
                    }}
                    value={transactionHash}
                    innerEndComponent={
                      <Button
                        size="small"
                        ariaLabel={transactionHashLabel}
                        variation="link"
                        isLoading={buttonLoading}
                        loadingText=" "
                        onClick={(e) => {
                          e.preventDefault();
                          setButtonPressed(true);
                        }}
                      >
                        <GPImage style={{width: 24, height: 24}} imgKey="icon-enter-wallet.svg"/>
                      </Button>
                    }
                  />
                </TableCell></TableRow>
                { attachTransactionResult?.operationHash ?
                <TableRow><TableCell textAlign="center">
                  <Button
                    //size="large"
                    ariaLabel="Attach transaction as displayed below."
                    variation="primary"
                    isLoading={buttonLoading}
                    loadingText=" "
                    onClick={(e) => {
                      e.preventDefault();
                      attachTransactionToOwner(email, transactionHash, attachTransactionResult.operationHash, setButtonLoading, setErrorMessage).then((result) => {
                        setAttachTransactionResult(result);
                      });
                    }}
                  >
                    Attach transaction as displayed below
                  </Button>
                </TableCell></TableRow> : null }</TableBody>
            </Table>
          </Card>
          { typeof attachTransactionResult?.result?.user === "object" && !Array.isArray(attachTransactionResult?.result?.user) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>User</h2>
            <Table size="small"><TableBody>
                <TableRow><TableCell textAlign="left">Name</TableCell><TableCell textAlign="left">{attachTransactionResult?.result?.user.name}</TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Address</TableCell><TableCell textAlign="left">
                  {attachTransactionResult?.result?.user.addressStreetNameHouseNumber}<br/>
                  {attachTransactionResult?.result?.user.addressZipCode}&nbsp;{attachTransactionResult?.result?.user.addressCity}<br/>
                  {attachTransactionResult?.result?.user.addressCountry}
                </TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Phone</TableCell><TableCell textAlign="left">{attachTransactionResult?.result?.user.phone}</TableCell></TableRow></TableBody>
            </Table>
          </Card> : null }
          { typeof attachTransactionResult?.result?.cryptoTransaction === "object" && !Array.isArray(attachTransactionResult?.result?.cryptoTransaction) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Transaction</h2>
            <Table size="small"><TableBody>
                <TableRow><TableCell textAlign="left">Date</TableCell><TableCell textAlign="left">{attachTransactionResult?.result?.cryptoTransaction.date}</TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Direction</TableCell><TableCell textAlign="left">{attachTransactionResult?.incommingTransaction ? "Incoming" : "Outgoing" }</TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Amount</TableCell><TableCell textAlign="left">{attachTransactionResult?.result?.cryptoTransaction.amount.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;Tron&nbsp;{attachTransactionResult?.result?.cryptoTransaction.tokenNameAbbreviation}</TableCell></TableRow>
                <TableRow><TableCell textAlign="left">From Address</TableCell><TableCell textAlign="left">
                  {attachTransactionResult?.result?.mountainwolfTransaction ? tronscanLink(attachTransactionResult?.result?.mountainwolfTransaction.DepositAddress) + " via " + tronscanLink(attachTransactionResult?.result?.cryptoTransaction.fromAddress) : tronscanLink(attachTransactionResult?.result?.cryptoTransaction.fromAddress)}
                </TableCell></TableRow>
                <TableRow><TableCell textAlign="left">To Address</TableCell><TableCell textAlign="left">{tronscanLink(attachTransactionResult?.result?.cryptoTransaction.toAddress)}</TableCell></TableRow>
                <TableRow><TableCell textAlign="left">Mountain Wolf </TableCell><TableCell textAlign="left">{attachTransactionResult?.result?.cryptoTransaction.fromAddress === "TCaQUJh5UojXuKAP1nwtnxs4BFC618MoQ4" ? "Yes" + (attachTransactionResult?.result?.mountainwolfTransaction ? ", with present transaction." : ", without present transaction!") : "No"}</TableCell></TableRow></TableBody>
            </Table>
          </Card> : null }
          { Array.isArray(attachTransactionResult?.result?.createdLots) && Array.isArray(attachTransactionResult?.result?.updatedLots) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Lots</h2>
            <div className="lots-list">
              {attachTransactionResult?.result?.createdLots.map(lot => {
                return (
                  <div key={show + Counter.next().value} className="lots-list-row">
                    <div className="lots-list-cell" key={show + Counter.next().value}><GPImage imgKey="icon-lots-lot.svg"/></div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lot.endNumber - lot.startNumber + 1} Lots</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{prettyGPDateFromISODateString(lot.date)}</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lot.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} USDT</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lotDescription(lot)}</div>
                  </div>
                );
              })}
              {attachTransactionResult?.result?.updatedLots.map(lot => {
                return (
                  <div key={show + Counter.next().value} className="lots-list-row">
                    <div className="lots-list-cell" key={show + Counter.next().value}><GPImage imgKey="icon-lots-lot.svg"/></div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lot.endNumber - lot.startNumber + 1} Lots</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{prettyGPDateFromISODateString(lot.date)}</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lot.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} USDT</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lotDescription(lot)}</div>
                  </div>
                );
              })}
            </div>
          </Card> : null }
          { Array.isArray(attachTransactionResult?.result?.createdCommissions) ?
          <Card className="white-card-admin">
            <GPImage className="main-icon" imgKey="icon-lots-progress.svg"/>
            <h2>Commissions</h2>
            <div className="lots-list">
              {attachTransactionResult?.result?.createdCommissions.map(commission => {
                return (
                  <div key={show + Counter.next().value} className="lots-list-row">
                    <div className="lots-list-cell" key={show + Counter.next().value}><GPImage imgKey="icon-lots-lot.svg"/></div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>Level&nbsp;{commission.level}</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{commission.endNumber - commission.startNumber + 1} Lots</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{prettyGPDateFromISODateString(commission.date)}</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{commission.value.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} USDT</div>
                    <div className="lots-list-cell" key={show + Counter.next().value}>{lotDescription(commission)}</div>
                  </div>
                );
              })}
            </div>
          </Card> : null }
        </>
      );
    case "withdrawal":
      const user = nextPendingWithdrawalResult?.result?.user;
      const userBalances = nextPendingWithdrawalResult?.result?.balances;
      const pendingWithdrawal = nextPendingWithdrawalResult?.result?.withdrawal;
      const totalAmountUSDT = nextPendingWithdrawalResult?.result?.totalAmountUSDT;
      const pendingWithdrawalsCount = nextPendingWithdrawalResult?.result?.pendingWithdrawalsCount;
      
      return(
        <Card className="white-card-admin-100">
          <h2>Total</h2>
          <Table size="small">
          <TableBody><TableRow>
            <TableCell textAlign="left">Pending amount</TableCell>
            <TableCell textAlign="right">{totalAmountUSDT ? totalAmountUSDT.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + "\u00a0USDT" : 0}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell textAlign="left">Pending count</TableCell>
            <TableCell textAlign="right">{pendingWithdrawalsCount ? pendingWithdrawalsCount : 0 }</TableCell>
          </TableRow></TableBody>
          </Table>
          <br/>
          <h2>Withdrawals</h2>
          <StepperField
            size="small"
            defaultValue={withdrawalIndex + 1}
            value={withdrawalIndex + 1}
            max={pendingWithdrawalsCount}
            min={1}
            step={1}
            onStepChange={(value) => {
              setWithdrawalIndex(value - 1);
              setNextPendingWithdrawalResult(null);
            }}
          />
          { nextPendingWithdrawalResult?.result && pendingWithdrawal ? <>
          <Table size="small"><TableBody>
            <TableRow>
              <TableCell textAlign="left">Creation Date</TableCell>
              <TableCell textAlign="right">{prettyGPDateFromISODateString(pendingWithdrawal.createdAt)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="left">Amount</TableCell>
              <TableCell textAlign="right">{pendingWithdrawal.amount.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + "\u00a0USDT"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="left">To Address</TableCell>
              <TableCell textAlign="right">
                {tronscanLink(pendingWithdrawal.toAddress)}
                <Button
                  style={{verticalAlign: "middle"}} 
                  size="small"
                  ariaLabel="Copy TRON wallet address to clipborad."
                  variation="link"
                  isLoading={buttonLoading}
                  loadingText=" "
                  onClick={(e) => {
                    e.preventDefault();
                    setButtonLoading(true);
                    window.navigator.clipboard.writeText(pendingWithdrawal.toAddress);
                    setTimeout(() => setButtonLoading(false));
                  }}
                >
                  <GPImage style={{width: 24, height: 24}} imgKey="icon-copy.svg"/>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell textAlign="left">Transaction Hash</TableCell>
              <TableCell>
                <TextField
                  size="small"
                  placeholder="7bf2275c1f2a1fca55a8effb372f0375f91ca51622218b9639ac7174fca08911"
                  hasError={ errorMessage ? true : false }
                  errorMessage={ errorMessage ? errorMessage : null }
                  label={transactionHashLabel} 
                  onChange={(e) => {
                    setTransactionHash(e.target.value?.trim());
                    setErrorMessage(null);
                  }}
                  onKeyUp={(e) => {
                    if((e.code === "Enter" || e.code === "NumpadEnter") && isTronHash(transactionHash)) {
                      setButtonPressed(true);
                      setErrorMessage(null);
                      pendingWithdrawal.hash = transactionHash;
                      setUpdateWithdrawal(pendingWithdrawal);
                    }
                  }}
                  value={transactionHash}
                  innerEndComponent={
                    <Button
                      size="small"
                      ariaLabel={transactionHashLabel}
                      variation="link"
                      isLoading={buttonLoading}
                      loadingText=" "
                      onClick={(e) => {
                        e.preventDefault();
                        setButtonPressed(true);
                        setErrorMessage(null);
                        pendingWithdrawal.hash = transactionHash;
                        setUpdateWithdrawal(pendingWithdrawal);
                      }}
                    >
                      <GPImage style={{width: 24, height: 24}} imgKey="icon-enter-wallet.svg"/>
                    </Button>
                  }
                />
                </TableCell>
              </TableRow></TableBody>
            </Table>
            <h2>For User</h2>
            <Table size="small">
              <TableBody><TableRow>
                <TableCell textAlign="left">Name</TableCell>
                <TableCell textAlign="right">{user?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell textAlign="left">Email</TableCell>
                <TableCell textAlign="right">{user?.email}</TableCell>
              </TableRow>
              {userBalances ? Object.keys(userBalances).map(key => {
                return (
                  <TableRow key={show + Counter.next().value}>
                    <TableCell key={show + Counter.next().value} textAlign="left">{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
                    <TableCell key={show + Counter.next().value} textAlign="right">{userBalances[key].toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}&nbsp;USDT</TableCell>
                  </TableRow>
                );
              }) : ""}
              <TableRow>
                <TableCell textAlign="left">Delete Withdrawal</TableCell>
                <TableCell textAlign="right">
                  <Button
                    size="small"
                    ariaLabel={transactionHashLabel}
                    
                    isLoading={buttonLoading}
                    loadingText=" "
                    onClick={(e) => {
                      e.preventDefault();
                      setButtonPressed(true);
                      setErrorMessage(null);
                      if(window.confirm("Are you sure to delete the widthdrawl with amount " + pendingWithdrawal.amount.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 2}) + "\u00a0USDT?")) {
                        setDeleteWithdrawal(pendingWithdrawal);
                      }
                    }}
                  >
                    <GPImage style={{width: 24, height: 24}} imgKey="icon-delete-wallet.svg"/>
                  </Button>
                </TableCell>
              </TableRow></TableBody>
            </Table>
          </>: "" }
        </Card>);
    default:
      return null;
  }
}