// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const WithdrawalState = {
  "NEW": "NEW",
  "SENT": "SENT",
  "CONFIRMED": "CONFIRMED",
  "DECLINED": "DECLINED",
  "FAILED": "FAILED"
};

const PerformanceType = {
  "DAILY": "DAILY"
};

const CryptoTransactionState = {
  "UNPROCESSED": "UNPROCESSED",
  "PROCESSED": "PROCESSED"
};

const CryptoTransactionDirectionType = {
  "INCOMING": "INCOMING",
  "OUTGOING": "OUTGOING",
  "PAYOUT": "PAYOUT"
};

const CryptoAddressType = {
  "TRON": "TRON",
  "TRON_CONTRACT": "TRON_CONTRACT",
  "TRON_COLD": "TRON_COLD",
  "TRON_HOT": "TRON_HOT"
};

const CryptoAddressHolderType = {
  "GROWTHPOOL": "GROWTHPOOL",
  "USER": "USER",
  "MOUNTAINWOLF": "MOUNTAINWOLF",
  "TOKEN_ISSUER": "TOKEN_ISSUER",
  "MULTIPLE": "MULTIPLE",
  "INVALID": "INVALID"
};

const PhoneType = {
  "MOBILE": "MOBILE",
  "OTHER": "OTHER"
};

const { Withdrawal, Parameter, Performance, Allocation, ManuallyAssignedCryptoTransaction, ReturnOnInvestment, Lot, Commission, MountainwolfTransaction, CryptoTransaction, CryptoAddress, User, PublicComponent, AdminComponent } = initSchema(schema);

export {
  Withdrawal,
  Parameter,
  Performance,
  Allocation,
  ManuallyAssignedCryptoTransaction,
  ReturnOnInvestment,
  Lot,
  Commission,
  MountainwolfTransaction,
  CryptoTransaction,
  CryptoAddress,
  User,
  PublicComponent,
  AdminComponent,
  WithdrawalState,
  PerformanceType,
  CryptoTransactionState,
  CryptoTransactionDirectionType,
  CryptoAddressType,
  CryptoAddressHolderType,
  PhoneType
};