import { DataStore } from 'aws-amplify';
import { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';
import { PublicComponent } from './models';

const GPConstants = {
  tronUSDTContractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  startDateISOString: "2021-12-01T00:00:00.000Z",
  startNumber: 1,
  nextMultiplicator: 6,
  lotStartNumber: 1,
  lotCount: 50000,
  lotUSDTValue: 100.0,
  agio: 0.05,
  commissionLevelPercentages: [ 0.1, 0.04, 0.03, 0.02, 0.01 ]
};


function poolNumberAndLotNumberForLotCount(lotCount, GPConstants) {
  let resultPoolNumber = 0;
  let resultLotNumber = 0;
  let currentLotCount = 0;
  let currentPoolNumber = 0;
  
  while(!resultPoolNumber && lotCount) {
    currentPoolNumber++;
    currentLotCount += amountOfLotsInPoolNumber(currentPoolNumber, GPConstants);
    if(lotCount <= currentLotCount) {
      resultPoolNumber = currentPoolNumber;
      resultLotNumber =  lotCount - (currentLotCount - amountOfLotsInPoolNumber(currentPoolNumber, GPConstants));
    }
  }
  
  return [resultPoolNumber, resultLotNumber];
}

function lotVerticaSymbol(poolNumber, lotNumber) {
  const verticalSymbols = ["α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ"];
  const startVerticalIndex = parseInt((lotNumber - 1) / (amountOfLotsInPoolNumber(poolNumber) / 10), 10);
  const endVerticalIndex = parseInt((lotNumber - 1) / (amountOfLotsInPoolNumber(poolNumber) / 10), 10);
  const startVerticalSymbol = verticalSymbols[startVerticalIndex];
  const endVerticalSymbol = verticalSymbols[endVerticalIndex];
  
  return startVerticalSymbol === endVerticalSymbol ? startVerticalSymbol : (startVerticalSymbol + "\u00a0-\u00a0" + endVerticalSymbol);
}

function amountOfLotsInPoolNumber(poolNumber) {
  if(poolNumber < 1) { return 0; }
  return parseInt(GPConstants.lotCount * (GPConstants.nextMultiplicator ** (parseInt(poolNumber, 10) - 1)), 10);
}

function convertToRoman(num) {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  var str = '';

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
}

function gaugeText(poolNumber, lotNumber) {
  return convertToRoman(poolNumber) + "\u00a0" + lotNumber + "\u00a0" + lotVerticaSymbol(poolNumber, lotNumber); 
}

export default function GPGauge({show, label, ...props}) {
  const [performance, setPerformance] = useState({ lotCount: 0, equivalentLotCount: 0 });
  
  useEffect(() => {
    const subscription = DataStore.observeQuery(PublicComponent, c => c.name.eq("performance")).subscribe(snapshot => {
      if(snapshot.items.length && snapshot.isSynced) {
        let newPerformance = 0;
        
        try {
          newPerformance = JSON.parse(snapshot.items[0].content);
        } catch (e) { console.error(e, snapshot.items[0].content)}
        
        if(newPerformance && (newPerformance.lotCount !== performance.lotCount || newPerformance.equivalentLotCount !== performance.equivalentLotCount) ) {
          setPerformance(newPerformance);
        }
      }
    });
    
    return () => {
      subscription?.unsubscribe();
    };
  }, [performance]);
  
  if(!performance || !performance.lotCount) {
    return null;
  }
  
  const [poolNumber, lotNumber] = poolNumberAndLotNumberForLotCount(performance.lotCount);
  const percentage = lotNumber / amountOfLotsInPoolNumber(poolNumber);
  
  return (
    <GaugeChart id="growthpool-perfomance-gauge"
      className="performance-gauge"
      cornerRadius={0} // 6
      marginInPercent={0.01} // 0.05
      //nrOfLevels={1000} // 420
      arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]} // null, if present disables nrOfLevels
      arcWidth={0.03} // 0.2
      arcPadding={0.02} // 0.05
      colors={["#6dc7ff", "#1a6dff", "#c822ff"]}
      needleColor="black"
      needleBaseColor="black"
      percent={percentage}
      textColor="grey"
      hideText={false}
      formatTextValue={() => { return gaugeText(poolNumber, lotNumber); }}
      animate={false}
    />
  );
}